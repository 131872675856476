import React from "react"


export default function Logout() {

    return (<div>Loging out please wait...{logout()}</div>);

}

function logout() {
    if (typeof window !== 'undefined') {
        localStorage.clear();
        sessionStorage.clear();
        window.location.pathname = 'home'
    }

}